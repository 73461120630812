import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { useAppSelector } from "./store";
import { useUIState } from "./hooks/useUIState";
import { useRefreshToken } from "./hooks/useRefreshToken";
import MainPage from "./components/UI/MainPage/MainPage";
import Loader from "./components/UI/Loader/Loader";
import AlertStack from "./components/UI/AlertStack/AlertStack";
import Auth from "./components/Container/Auth/Auth";
import "./assets/styles/index.scss";
import "./App.css";
import { AuthActions } from "./store/entities/auth/auth.actions";
import { useDispatch } from "react-redux";

function App() {
  const [tableIsLoading, setTableIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  const { isLoading } = useUIState();
  const { checkRefreshTokenExp } = useRefreshToken();

  const { isLoggedIn } = useAppSelector((state: any) => state.auth);

  useEffect(() => {
    let refreshTokenInterval: any;

    // Check if token is valid every 10 seconds
    refreshTokenInterval = setInterval(() => checkRefreshTokenExp(), 10000);

    return () => {
      clearInterval(refreshTokenInterval);
    };
  }, [checkRefreshTokenExp]);

  // Fix showing loader for another short duration when tables are not rendered yet - works with useUIState useEffect logic
  useEffect(() => {
    let loadingStateTimeout: any;

    if (isLoading) {
      setTableIsLoading(true);
    } else {
      loadingStateTimeout = setTimeout(() => {
        setTableIsLoading(false);
      }, 500);
    }

    return () => {
      clearTimeout(loadingStateTimeout);
    };
  }, [isLoading]);

  useEffect(() => {
    if (isLoggedIn) {
      const userData = localStorage.getItem("lockerim_userData");
      if (userData) {
        dispatch(AuthActions.getPermissionsAfterLogin(JSON.parse(userData).token));
      }
    }
  }, []);

  return (
    <AlertStack>
      <div className="App">
        {(isLoading || tableIsLoading) && <Loader />}
        <Routes>
          {isLoggedIn && <Route path={"*"} element={<MainPage />} />}
          {!isLoggedIn && <Route path={"*"} element={<Auth />} />}
        </Routes>
      </div>
    </AlertStack>
  );
}

export default App;
